export const Card = () => {
    return  <div className="card">
        <div className="image">
            <img src={"https://northernspiritrc.ca/wp-content/uploads/2020/03/soon1-1-300x300-1.png"}/>
        </div>
        <div className="details">
            <div className="center">
                <h1>IN PROGRESS<br/><span>in progress</span></h1>
                <p>This is in progress. There is no project displayed at this moment.</p>
                <ul>
                    <li className={"technology-tag"}>Progress Technology</li>
                    <li className={"technology-tag"}>Java</li>
                    <li className={"technology-tag"}>React</li>
                    <li className={"technology-tag"}>Other</li>
                    <li className={"technology-tag"}>Web Scrapping</li>
                    <li className={"technology-tag"}>AWS</li>

                </ul>
                {/*<ul>*/}
                {/*    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>*/}
                {/*    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>*/}
                {/*    <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>*/}
                {/*    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>*/}
                {/*    <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>*/}
                {/*</ul>*/}
            </div>
        </div>
    </div>
}
