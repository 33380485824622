import "./index.scss";

type Props = {
    letterClass: any; strArray: any; idx: any;
}

export const AnimatedLetters = (props: Props) => {
    return <span>
        {props.strArray.map((char: any, i: number) =>
            <span key={i} className={`${props.letterClass} _${i + props.idx}`}>
                 {char}
        </span>)}
    </span>
}