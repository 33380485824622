import "./index.scss";
import Loader from "react-loaders";

export const Education = () => {
    return <>
        <div className={"education-wrapper"}>
            <div>
                <h1>Education </h1>
                <div className={"education-section"}>
                    <div className={"school"}>
                        <img src={"/education/ase.png"}/>
                        <div className={"text-zone"}>
                            <h1>Faculty of Economic Cybernetics, Statistics and Computer Science, ASE Bucharest</h1>
                            <p>Bachelor's degree, Computer Science</p>
                            <p>2018 - 2021</p>
                            <p>Grade: 10.00</p>
                        </div>
                    </div>
                    <div className={"school"}>
                        <img src={"/education/ase.png"}/>
                        <div className={"text-zone"}>
                            <h1>Faculty of Economic Cybernetics, Statistics and Computer Science, ASE Bucharest</h1>
                            <p>Master's degree, Data Modeling/Warehousing and Database Administration</p>
                            <p>2021 - current</p>
                            <p>Grade: Probably 10.00</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1>Courses & Certifications </h1>
                <div className={"education-section-animated"}>
                    <div className={"animated-target"}>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>React - The Complete Guide (incl Hooks, React Router, Redux)</h1>
                                <p>Author: Maximilian Schwarzmüller</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>Java Programming Masterclass covering Java 11 & Java 17</h1>
                                <p>Author: Tim Buchalka</p>
                                <p>Year achieved: 2020</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/oracle.png"}/>
                            <div className={"text-zone"}>
                                <h1>Oracle Academy Java Programming </h1>
                                <p>Author: Oracle</p>
                                <p>Year achieved: 2022</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>The Web Developer Bootcamp</h1>
                                <p>Author: Colt Steele</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>Understanding NPM - Node.js Package Manager</h1>
                                <p>Author: Bogdan Stashchuk</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>

                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>React - The Complete Guide (incl Hooks, React Router, Redux)</h1>
                                <p>Author: Maximilian Schwarzmüller</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>Java Programming Masterclass covering Java 11 & Java 17</h1>
                                <p>Author: Tim Buchalka</p>
                                <p>Year achieved: 2020</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/oracle.png"}/>
                            <div className={"text-zone"}>
                                <h1>Oracle Academy Java Programming </h1>
                                <p>Author: Oracle</p>
                                <p>Year achieved: 2022</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>The Web Developer Bootcamp</h1>
                                <p>Author: Colt Steele</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>
                        <div className={"school"}>
                            <img src={"/education/udemy.png"}/>
                            <div className={"text-zone"}>
                                <h1>The Web Developer Bootcamp</h1>
                                <p>Author: Bogdan Stashchuk</p>
                                <p>Year achieved: 2021</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader type="line-scale-party" innerClassName={"ball"} active={true}/>

    </>
}
