import "./index.scss";
import {Circle, MapContainer, Popup, TileLayer} from "react-leaflet";

export const Map = () => {
    return <div className="map-wrap">

        <div className="info-map">
            Adrian S.,
            <br/>
            Bucharest, Romania
            <br/>
            <br/>
            <span>email: asoare2906@gmail.com</span>
            <br/>
            {/*<span>phone: +40736113749</span>*/}
        </div>
        <MapContainer center={[44.4268, 26.1025]} zoom={14} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                subdomains={"abcd"}
                maxZoom={20}
            />
            {/*<Circle center={{lat: 44.4268, lng:26.1025}}>*/}
            {/*    <Popup>*/}
            {/*        A pretty CSS3 popup. <br/> Easily customizable.*/}
            {/*    </Popup>*/}
            {/*</Circle>*/}
        </MapContainer>
    </div>
}
