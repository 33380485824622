import "./index.scss";
import {useEffect} from "react";
// @ts-ignore
import LogoS from "../../../assets/images/logo-s.svg"

export const Logo = () => {


    useEffect(() => {
        let paths = document.querySelectorAll("svg path")
        let i = 0;
        paths.forEach(function (item: any, index) {
            i++;
            console.log(item)
            let pathLength = item.getTotalLength();
            item.setAttribute("stroke-dasharray", pathLength);
            item.setAttribute("stroke-dashoffset", pathLength);
            item.innerHTML = "<animate attributeName='stroke-dashoffset' begin='1s' dur='5s' to='0' fill='freeze'/>"
            console.log(index, pathLength, item.innerHTML)
        })
    }, [])
    return <>
        <div className="logo-container">

            <img
                className="solid-logo"
                src={LogoS}
                alt="JavaScript,  Developer"
                translate={"yes"}
            />

            <svg viewBox="0 0 365.43 320.08">
                <path className="cls-1" fill="none" stroke={"white"} viewBox="0 0 365.43 320.08"
                      d="M231.79,80.65l76.59.53a.36.36,0,0,1,.33.21L443.9,390.71a.35.35,0,0,1-.33.5l-71.5-.49a.37.37,0,0,1-.33-.23L340,314.09a.36.36,0,0,0-.33-.22l-145-1a.36.36,0,0,0-.34.22L162,389a.36.36,0,0,1-.34.22l-69.33-.48a.37.37,0,0,1-.33-.51L231.47,80.86A.32.32,0,0,1,231.79,80.65Zm88.77,184.1L269.17,135.4a.36.36,0,0,0-.66,0L214.47,264a.37.37,0,0,0,.33.5l105.42.74A.37.37,0,0,0,320.56,264.75Z"
                      transform="translate(-79.51 -79.65)"/>
                <path className="cls-1" fill="none" stroke={"white"}
                      d="M220.35,88.16l76.59.53a.35.35,0,0,1,.32.22l135.2,309.32a.36.36,0,0,1-.33.5l-71.51-.5a.35.35,0,0,1-.32-.22L328.54,321.6a.35.35,0,0,0-.32-.22l-145-1a.37.37,0,0,0-.33.22l-32.38,76a.37.37,0,0,1-.33.22l-69.34-.48a.36.36,0,0,1-.32-.51L220,88.37A.36.36,0,0,1,220.35,88.16Zm88.76,184.11L257.73,142.91a.36.36,0,0,0-.66,0L203,271.53a.36.36,0,0,0,.33.5l105.42.73A.35.35,0,0,0,309.11,272.27Z"
                      transform="translate(-79.51 -79.65)"/>
            </svg>

        </div>
    </>
}