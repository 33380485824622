import "./index.scss";
// @ts-ignore
import LogoS from "../../assets/images/logo-s.svg";
import {Link, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faUser, faEnvelope, faGraduationCap, faPhone, faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import {faGithub, faLinkedin, } from "@fortawesome/free-brands-svg-icons";

export const Sidebar = () => {
    return <div className={"nav-bar"}>
        <Link className={"logo"} to={"/"}>
            <img src={LogoS} alt={"logo"}/>
            <h2 className={"sub-logo"}>Web Developer</h2>
        </Link>
        <nav>
            <NavLink className={"home-link"} to={"/"}>
                <FontAwesomeIcon icon={faHome} color={"#4d4d4e"}/>
                <div className="overlay">
                    <div className="text">HOME</div>
                </div>
            </NavLink>
            <NavLink className={"about-link"} to={"/about"}>
                <FontAwesomeIcon icon={faUser} color={"#4d4d4e"}/>
                <div className="overlay">
                    <div className="text">ABOUT</div>
                </div>
            </NavLink>
            <NavLink className={"projects-link"} end={true} to={"/projects"}>
                <FontAwesomeIcon icon={faLaptopCode} color={"#4d4d4e"}/>
                <div className="overlay">
                    <div className="text">PROJECTS</div>
                </div>
            </NavLink>
            <NavLink className={"education-link"} end={true} to={"/education"}>
                <FontAwesomeIcon icon={faGraduationCap} color={"#4d4d4e"}/>
                <div className="overlay">
                    <div className="text">EDUCATION</div>
                </div>
            </NavLink>
            <NavLink className={"contact-link"} end={true} to={"/contact"}>
                <FontAwesomeIcon icon={faEnvelope} color={"#4d4d4e"}/>
                <div className="overlay">
                    <div className="text">CONTACT</div>
                </div>
            </NavLink>
        </nav>
        <div className={"sub-nav"}>
            <a target={"_blank"} rel={"noreferrer"} href={"https://www.linkedin.com/in/adrian-soare/"}>
                <FontAwesomeIcon icon={faLinkedin} color={"#4d4d4e"}/>
            </a>
            <a target={"_blank"} rel={"noreferrer"} href={"https://github.com/adi2906"}>
                <FontAwesomeIcon icon={faGithub} color={"#4d4d4e"}/>
            </a>

            <a target={"_blank"} rel={"noreferrer"} href={"mailto:asoare2906@gmail.com"}>
                <FontAwesomeIcon icon={faEnvelope} color={"#4d4d4e"}/>
            </a>
        </div>
    </div>
}
