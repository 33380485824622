import {SkillsBall} from "../SkillsBall";
import "./index.scss";
import {useEffect, useState} from "react";
import {AnimatedLetters} from "../animatedLetters";
import Loader from "react-loaders";

export const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect((): any => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (<>
        <div className="container about-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                        idx={15}
                    />
                </h1>

                <div className={"about-text"}>
                    <p>
                        I am an enthusiastic and ambitious software developer with a passion for developing applications
                        in JavaScript environments such as Node.JS (Express.js, Next.js), but also web SPA, using
                        React.js.
                    </p>
                    <p>
                        The projects I worked on and the experience I gained from my latest projects led me to
                        challenge myself daily and to learn about new technologies that helped me to do a better work.
                    </p>
                    <p>

                    </p>
                </div>
            </div>

            <div className="stage-cube-cont">
                <SkillsBall/>
            </div>

        </div>
        <Loader type="line-scale-party" innerClassName={"ball"} active={true}/>
    </>)
}
