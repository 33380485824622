import React, {useEffect} from "react";
import "./SkillsBall.scss";
import useWindowDimensions from "../utils/getWindowDimensions";
declare const TagCloud:any;



export const SkillsBall = () => {

    const { height, width } = useWindowDimensions();
    console.log(height, width)

    const tags = [
        'JavaScript', 'CSS', 'HTML',
        'C++', 'React',
        'Java', 'git',
        'Node.js', 'MongoDB',
        'MySQL', 'Firebase', 'PL/SQL',
        'TypeScript', 'Bulma',
        'Bootstrap', "PrimeFaces", "Web Scraping", "puppeteer.js", "Redux", "Redux-Saga",
        "express.js",
    ];

    useEffect(()=> {
        let tagCloud = TagCloud('.contentz', tags, {radius:width > 1400 ? 300 : height/5, opacity: 1, maxSpeed: "fast"});
        console.log("test")
    },[])

    return <div className="contentz"/>
}
