import {useEffect, useState} from "react";
import "./index.scss"
import {Card} from "./card";
import Loader from "react-loaders";


export const Projects = () => {
    const [myState, setMyState] = useState(["1", "2", "3", "4", "5", "6", "7", "8"]);

    function shuffleArray(array : any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;

    }
    useEffect(() => {
        let myArr = ["1", "2", "3", "4", "5", "6", "7", "8"];
        shuffleArray(myArr);
        console.log(myArr)
         setTimeout(() => {
            setMyState(myArr);
            console.log("new array")
        },3000);
    }, [])
    return <div className="grid-container">
        {myState.map((el, index) => <Card key={index}/> )}
        <Loader type="line-scale-party" innerClassName={"ball"} active={true}/>

    </div>
}
