import React from 'react';
import './App.scss';
import {Layout} from "./components/layout/index";
import {Route, Routes} from "react-router-dom";
import {Home} from "./components/home";
import {About} from "./components/about";
import {Contact} from "./components/contact";
import {Projects} from "./components/projects";
import {Education} from "./components/education";

function App() {

    return (
        <>
        <Routes>
            <Route path={"/"} element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path={"about"} element={<About/>}/>
                <Route path={"contact"} element={<Contact/>}/>
                <Route path={"projects"} element={<Projects/>}/>
                <Route path={"education"} element={<Education/>}/>
            </Route>


        </Routes>
        </>
  );
}

export default App;
