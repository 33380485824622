import "./index.scss";
import {Sidebar} from "../sidebar";
import {Outlet} from "react-router-dom";
import Loader from "react-loaders";
// @ts-ignore
import LogoS from "../../assets/images/logo-s.svg";
import {BurgerMenu} from "../sidebar/burgerMenu";

export const Layout = () => {
    return <div className={"App"} id="outer-container">
        <Sidebar/>
        <BurgerMenu/>

        <main id="page-wrap">


        <div className={"page"}>

            <span className={"tags top-tags"}>
                <span> &lt;html&gt; </span>
                <br/>
                <span> &lt;body&gt; </span>
            </span>

            <Outlet/>
            <span className={"tags bottom-tags"}>
                <span> &lt;/body&gt; </span>
                <br/>
                <span> &lt;/html&gt; </span>
            </span>
        </div>
        <Loader type="ball-pulse" innerClassName={"ball"} active={true}/>
        </main>

    </div>
}