import "./index.scss";
import {Link, NavLink} from "react-router-dom";
// @ts-ignore
import LogoS from "../../../assets/images/logo-s.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faGraduationCap, faHome, faLaptopCode, faUser} from "@fortawesome/free-solid-svg-icons";
import {scaleRotate as Menu} from "react-burger-menu";

export const BurgerMenu = () => {
    return <Menu right={true} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
        <Link className={"logo"} to={"/"}>
            <img src={LogoS} alt={"logo"}/>
            <h2 className={"sub-logo"}>Web Developer</h2>
        </Link>
        <nav>
            <NavLink className={"home-link"} to={"/"}>
                <FontAwesomeIcon icon={faHome} color={"#4d4d4e"}/>
                <span>HOME</span>
            </NavLink>
            <NavLink className={"about-link"} to={"/about"}>
                <FontAwesomeIcon icon={faUser} color={"#4d4d4e"}/>
                <span>ABOUT</span>
            </NavLink>
            <NavLink className={"projects-link"} end={true} to={"/projects"}>
                <FontAwesomeIcon icon={faLaptopCode} color={"#4d4d4e"}/>
                <span>PROJECTS</span>
            </NavLink>
            <NavLink className={"education-link"} end={true} to={"/education"}>
                <FontAwesomeIcon icon={faGraduationCap} color={"#4d4d4e"}/>
                <span>EDUCATION</span>
            </NavLink>
            <NavLink className={"contact-link"} end={true} to={"/contact"}>
                <FontAwesomeIcon icon={faEnvelope} color={"#4d4d4e"}/>
                <span>CONTACT</span>
            </NavLink>
        </nav>
    </Menu>
}
