import "./index.scss";
import {AnimatedLetters} from "../animatedLetters";
import {useEffect, useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import {Map} from "./map";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css"
import Loader from "react-loaders";
// import 'react-toastify/dist/ReactToastify.css';



const schema = yup.object({
    name: yup.string().required("Name is required!"),
    email: yup.string().required("Email is required!").email("Enter a valid email!"),
    subject: yup.string().required("Subject is required!").min(5, "Subject must have at least 5 characters").max(100),
    message: yup.string().required("Message is required!").min(20, "Message must have at least 20 characters"),

}).required();

export const Contact = () => {
    const notify = () => toast.success('Your application was successfully sent! Thanks:)', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const {register, handleSubmit, reset, formState: {errors}} = useForm<any>({
        resolver: yupResolver(schema)
    });
    const onSubmit = (data: any, e : any) => {
        e.preventDefault()
        console.log(data)
        emailjs
            .sendForm('service_lv0f9sc', 'template_q3iwwzt', form.current, 'G7YWgk9nW-La9yWL1')
            .then(() => {
                notify();
            }, () => {
                alert('Failed to send the message, please try again')
            })
        reset();
    };
    console.log("error", errors);

    const [letterClass, setLetterClass] = useState<any>('text-animate')
    const form = useRef<any>()

    useEffect((): any => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e: any) => {
        e.preventDefault()

        emailjs
            .sendForm('service_lv0f9sc', 'template_q3iwwzt', form.current, 'G7YWgk9nW-La9yWL1')
            .then(() => {
                alert('Message successfully sent!')
                // @ts-ignore
                window.location.reload(false)
            }, () => {
                alert('Failed to send the message, please try again')
            })
    }

    return <>
        <div className="container contact-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                        idx={15}
                    />
                </h1>
                <p className={"para"}>
                    I am interested in freelance opportunities - especially ambitious or
                    large projects. However, if you have other request or question,
                    don't hesitate to contact me.
                </p>
                <div className="contact-form">

                    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <input className={errors?.name?.message ? "input-error" : ""}
                                placeholder="Name"
                                   type="text"
                                   {...register("name", {
                                       required: true, minLength: 20, max: 23, maxLength: 80
                                   })}></input>
                            <p className={"error-message"}>{errors.name?.message as any}</p>
                        </div>
                        <div>
                            <input className={errors?.email?.message ? "input-error" : ""}
                                placeholder="Email"
                                   type="text"
                                   {...register("email", {required: true, max: 23, maxLength: 80})}
                            />
                            <p className={"error-message"}>{errors.email?.message as any}</p>
                        </div>
                        <div>
                            <input className={errors?.subject?.message ? "input-error" : ""}
                                placeholder="Subject"
                                   type="text"
                                   {...register("subject", {required: true, max: 23, maxLength: 80})}
                            />
                            <p className={"error-message"}>{errors.subject?.message as any}</p>
                        </div>
                        <div>
                        <textarea className={errors?.message?.message ? "input-error" : ""}
                            placeholder="Message"
                                  {...register("message", {required: true, max: 23, maxLength: 80})}
                        />
                            <p className={"error-message"}>{errors.message?.message as any}</p>
                        </div>
                        <div className={"btn-wrapper"}>
                            <input type="submit"
                                   className="flat-button"
                                   value="Send"/>
                        </div>
                    </form>
                </div>
            </div>
            <Map/>
        </div>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={"dark"}
        />
        <Loader type="line-scale-party" innerClassName={"ball"} active={true}/>
    </>
}
