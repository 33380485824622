// @ts-ignore
import LogoTitle from "../../assets/images/logo-s.svg";
import {Link} from "react-router-dom";
import "./index.scss";
import {useEffect, useState} from "react";
import {AnimatedLetters} from "../animatedLetters";
import {Logo} from "./logo";
import Loader from "react-loaders";


export const Home = () => {

    const [letterClass, setLetterClass] = useState("text-animate");
    const nameArray = ["d", "r", "i", "a", "n", ","];
    const jobArray = [
        'w',
        'e',
        'b',
        ' ',
        'd',
        'e',
        'v',
        'e',
        'l',
        'o',
        'p',
        'e',
        'r',
        '.',
    ]

    useEffect((): any => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])


    return <>
        <div className={"container home-page"}>
            <div className={"text-zone"}>
                <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i</span>
                    <span className={`${letterClass} _13`}>!</span>
                    <br/>
                    <span className={`${letterClass} _14`}>I</span>
                    <span className={`${letterClass} _15`}>'</span>
                    <span className={`${letterClass} _16`}>m</span>
                    <img src={LogoTitle} alt={"Logo"} style={{width: "65px"}}/>
                    <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>

                    <br/>
                    <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22}/>
                </h1>
                <h2>Software Developer / Javascript Developer</h2>
                <Link to={"/contact"}> <button className={"glow-on-hover"}>Contact me </button></Link>

            </div>
            <Logo/>
        </div>
        <Loader type="line-scale-party" innerClassName={"ball"} active={true}/>
    </>
}
